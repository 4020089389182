import React from 'react';
import loadable from '@loadable/component';
import { ReduxProps } from 'app/types';

const CreateSearchAlertModal = loadable(
    () =>
        import(
            /* webpackChunkName: "HeaderFooterTemplate.loadable" */ 'app/shared/modules/user-search/CreateSearchAlertModalComponent'
        )
);

const LoadableCreateSearchAlertModal = (props: ReduxProps) => {
    return <CreateSearchAlertModal {...props} />;
};

export default LoadableCreateSearchAlertModal;
