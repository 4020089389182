// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Promise from 'promise';
import { withRouter } from 'react-router-dom';
import includes from 'lodash/includes';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AdActions from 'app/shared/flux/actions/AdActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import AreaActions from 'app/shared/flux/actions/AreaActions';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';

// Components
import CreateSearchAlertModal from 'app/shared/modules/user-search/CreateSearchAlertModal';
import MapWrapper from 'app/shared/wrappers/MapWrapper';
import NotFoundPage from 'app/shared/pages/NotFoundPage';
import OptInSearchAlertsModal from 'app/shared/modules/OptInSearchAlertsModal';
import SplitMapSidebar from 'app/shared/modules/SplitMapSidebar';
import SlidingPreviewContainer from 'app/shared/modules/map/SlidingPreviewContainer';

// Misc / Utils
import 'app/shared/templates/SplitMapTemplate/style.scss';
import { getCurrentListingIndex } from 'app/shared/utils/listingsReducerUtils';
import { shouldShowPreview, listingInCurrentArea } from 'app/shared/templates/SplitMapTemplate/controller';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';

const logger = getLogger('templates/splitMapTemplate');
const { bool, object } = PropTypes;
class SplitMapTemplate extends React.Component {
    static propTypes = {
        area: object,
        border: bool,
        currentListing: object,
        gmapLoaded: bool,
        isAreaUrl: bool,
        isInitialSsrPage: bool,
        isListingLoading: bool,
        isMobile: bool,
        isPadOrBuildingUrl: bool,
        previewListing: object,
        showCreateSearchAlert: bool
    };

    static defaultProps = {
        area: {},
        currentListing: null,
        gmapLoaded: false,
        border: null,
        isAreaUrl: true,
        isInitialSsrPage: true,
        isListingLoading: false,
        isMobile: false,
        isPadOrBuildingUrl: false,
        previewListing: null,
        showCreateSearchAlert: false
    };

    constructor(props) {
        super(props);

        this.state = {
            hideMobileRightSidebar: false
        };

        this.LAST_SCROLL_TOP = 0;

        this.debouncedAggregate = [];
        this.debounceLastRun = 0;

        // wait 300ms before actually triggering a fetch listing
        // this is to pool prop updates into a single fetch listing
        this.debounceTimeMs = 500;
        this.debouncedTimeout = null;

        this.fetchListingsCount = 0;
    }

    componentDidMount() {
        const { currentListing, isAreaUrl, isPadOrBuildingUrl, isInitialSsrPage, isMobile, pathname } = this.props;
        const isFsSRP = includes(pathname, 'for-sale') && isAreaUrl;

        if (isFsSRP) {
            let pathparts = pathname.split('/');
            pathparts[pathparts.length - 1] = 'apartments-for-rent';
            window.router.transitionTo(pathparts.join('/'));
        }
        if (isAreaUrl && !isInitialSsrPage) {
            return this.batchedFetchListings();
        } else if (isPadOrBuildingUrl && currentListing) {
            // get some listings for hdp to hdp nav
            if (isInitialSsrPage && isMobile) {
                this.fetchListings();
            }
            return this.handleListingPageUpdates();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            dispatch,
            existsOutsideOfList,
            isAreaUrl,
            isNearMeUrl,
            isMobile,
            isInitialSsrPage,
            isPadOrBuildingUrl,
            isValidAreaPage,
            isValidSearchSlug,
            pathname,
            query
        } = this.props;
        const pathChanged = prevProps.pathname !== pathname;
        const isDesktop = isMobile === false;

        // SSR Area Page - No Query Params
        const ssrAreaPageWithNoQuery = (isAreaUrl || isNearMeUrl) && isInitialSsrPage && isEmpty(query);

        // CSR Area Page - No Query Params
        const csrAreaPageWithNoQuery = (isAreaUrl || isNearMeUrl) && !isInitialSsrPage && isEmpty(query);

        // SSR Area Page With Query Params sans lat/lng/z info
        const ssrAreaPageWithQuerySansGeoInfo =
            (isAreaUrl || isNearMeUrl) && isInitialSsrPage && !isEmpty(query) && !routeUtils.hasLatAndLon(query);

        // SSR Area Page With Query Params
        const ssrAreaPageWithQuery = (isAreaUrl || isNearMeUrl) && isInitialSsrPage && !isEmpty(query);

        // CSR Area Page With Query Params
        const csrAreaPageWithQuery = (isAreaUrl || isNearMeUrl) && !isInitialSsrPage && !isEmpty(query);

        const handleListingsUpdate = () => {
            if (existsOutsideOfList) {
                dispatch(ListingEngineActions.setCurrentListingOutsideOfList(false));
            }

            if (pathChanged && !isValidAreaPage) {
                dispatch(AppActions.setAppStoreBool('isValidAreaPage', true));
            }
            if (pathChanged && !isValidSearchSlug) {
                dispatch(AppActions.setAppStoreBool('isValidSearchSlug', true));
            }
            return this.debouncedListingsHandler(this.batchedFetchListings, prevProps);
        };

        if (ssrAreaPageWithNoQuery || ssrAreaPageWithQuerySansGeoInfo) {
            // Do nothing since listings are already SSR'd - Prevents listings flash we've been seeing on SSR Area Pages
            return;
        } else if (csrAreaPageWithNoQuery || ssrAreaPageWithQuery || csrAreaPageWithQuery) {
            return handleListingsUpdate();
        } else if (isPadOrBuildingUrl) {
            return this.handleListingPageUpdates(prevProps).then(() => {
                /**
                 * Needed for scenarios when a user SSRs into an HDP or a BDP and clicks "close" - the app needs to
                 * have fetched listings in order for the close button to work properly.
                 */
                if (isDesktop) {
                    return this.debouncedListingsHandler(this.batchedFetchListings, prevProps);
                }
            });
        }
    }

    componentWillUnmount() {
        if (!this.props.isValidAreaPage) {
            this.props.dispatch(AppActions.setAppStoreBool('isValidAreaPage', true));
        }
        if (!this.props.isValidSearchSlug) {
            this.props.dispatch(AppActions.setAppStoreBool('isValidSearchSlug', true));
        }
    }

    getShowMap() {
        const { isMobile } = this.props;
        const { hideMobileRightSidebar } = this.state;

        return isMobile ? hideMobileRightSidebar : true;
    }

    /*
     *   aggregates and manages list of prop updates
     *   that correspond to needing to fetch listings
     *   waits {x} seconds before executing batchedFetchListings
     *   purpose is to batch multiple prop updates that should only correspond to a single listing update
     *   e.g. when panning, the lat/lon and area may change rapidly. we only want to fetch once.
     *
     *   TODO: look into if handling listing fetches in response to prop changes is a valid strategy
     */
    debouncedListingsHandler = (func, prevProps) => {
        const { area, border, dispatch, isLoggedIn, page, pathname, isMobile, gmapLoaded, query, slug } = this.props;
        const now = Date.now();
        const areaChanged = prevProps.area.resourceId !== area.resourceId;
        const hasSlugChanged = prevProps.slug !== slug;
        const hasPageChanged = prevProps.page !== page;
        const hasLatAndLon = routeUtils.hasLatAndLon(query);
        const gmapFinishedLoading = !prevProps.gmapLoaded && gmapLoaded;
        const hasUserLoggedIn = prevProps.isLoggedIn !== isLoggedIn;
        const borderChange = prevProps.border !== border;
        const latLonZoomChanged =
            (hasLatAndLon && prevProps.query && query && prevProps.query.lat !== query.lat) ||
            prevProps.query.lon !== query.lon ||
            prevProps.query.z !== query.z;

        // if on desktop view do not fetch listings before gmapLoaded
        if (!isMobile && !gmapLoaded) {
            return false;
        }

        // need to fetch listings if we are going hdp -> srp on mobile
        if (isMobile && !routeUtils.isAreaUrl(prevProps.pathname) && routeUtils.isAreaUrl(pathname)) {
            this.debouncedAggregate.push('mobileSrpLoad');
        }

        if (areaChanged && area.resourceId) {
            this.debouncedAggregate.push('area');
        }

        if (hasSlugChanged) {
            this.debouncedAggregate.push('slug');
        }

        if (hasPageChanged) {
            this.debouncedAggregate.push('pagination');
        }

        if (latLonZoomChanged) {
            this.debouncedAggregate.push('latLonZoom');
        }

        if (hasUserLoggedIn) {
            this.debouncedAggregate.push('login');
        }

        if (gmapFinishedLoading) {
            this.debouncedAggregate.push('gmapLoaded');
        }

        if (borderChange) {
            this.debouncedAggregate.push('border');
        }

        if (routeUtils.isPadOrBuildingUrl(pathname) && pathname !== prevProps.pathname) {
            this.debouncedAggregate.push('hdpChange');
        }

        if (!this.debouncedTimeout && !isEmpty(this.debouncedAggregate)) {
            const timeSinceLastRunMs = now - this.debounceLastRun;

            if (timeSinceLastRunMs > this.debounceTimeMs) {
                dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', false));

                this.debouncedTimeout = window.setTimeout(() => {
                    func(prevProps);
                }, this.debounceTimeMs);
            }
        }
    };

    /*
     *   checks to make sure we have a valid reason for fetching listings
     *   resets debounce aggregator and timeout
     *   execute listing fetch
     */
    batchedFetchListings = () => {
        // useful for seeing what prop changes are triggering a listings fetch
        logger.debug('RUNNING FUNC - EXECUTE', this.debouncedAggregate);

        if (!isEmpty(this.debouncedAggregate)) {
            this.debouncedAggregate = [];

            if (this.debouncedTimeout) {
                window.clearTimeout(this.debouncedTimeout);

                this.debouncedTimeout = null;
            }

            return this.fetchListings();
        }
    };

    handleListingPageUpdates = (prevProps = {}) => {
        const { area, currentListing, query, dispatch } = this.props;
        const listingFromEmail = routeUtils.hasValidMinMaxLatLon(query);
        const thisPropsMlid = (currentListing || {}).maloneLotIdEncoded;
        const prevPropsMlid = (prevProps.currentListing || {}).maloneLotIdEncoded;

        if (currentListing && thisPropsMlid !== prevPropsMlid && thisPropsMlid) {
            // Get city-level resourceId for listing.
            let inArea = listingInCurrentArea({
                resourceId: area.resourceId,
                currentListing
            });

            // Only fetch new areas and / or listings under these conditions.
            if (listingFromEmail) {
                return this.getSavedSearchArea().then((result) => {
                    return this.fetchArea(result.resourceId).catch((error) => {
                        dispatch(
                            ErrorActions.errorHandler({
                                error,
                                errorLocation: 'component.splitMapTemplate.handleListingPageUpdates',
                                erorrClass: 'areaActions'
                            })
                        );
                    });
                });
            } else if (!inArea || isEmpty(area)) {
                if (isEmpty(currentListing.areas.breadcrumbs)) {
                    let options = {
                        lat: currentListing.geo.lat,
                        lon: currentListing.geo.lon
                    };

                    return dispatch(AreaActions.getAreaByLatLon(options))
                        .then((areaResult = {}) => this.fetchArea(areaResult.resourceId))
                        .catch((error) => {
                            dispatch(
                                ErrorActions.errorHandler({
                                    error,
                                    errorLocation: 'component.splitMapTemplate.handleListingPageUpdates',
                                    errorClass: 'areaActions'
                                })
                            );
                        });
                } else {
                    let areaResourceId =
                        currentListing.areas.breadcrumbs.length > 1
                            ? currentListing.areas.breadcrumbs[1].resourceId
                            : currentListing.areas.breadcrumbs[0].resourceId;
                    return this.fetchArea(areaResourceId).catch((error) => {
                        dispatch(
                            ErrorActions.errorHandler({
                                error,
                                errorLocation: 'component.splitMapTemplate.handleListingPageUpdates',
                                errorClass: 'areaActions'
                            })
                        );
                    });
                }
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    };

    getSavedSearchArea = () => {
        const { query, dispatch } = this.props;

        return dispatch(
            AreaActions.getBestFitArea({
                minLat: query.minLat,
                maxLat: query.maxLat,
                minLon: query.minLon,
                maxLon: query.maxLon
            })
        );
    };

    fetchArea = (areaResourceId) => {
        const { pathname, dispatch, isMobile } = this.props;
        const { hideMobileRightSidebar } = this.state;
        const isMapView = isMobile ? hideMobileRightSidebar : true;
        areaResourceId = areaResourceId || routeUtils.getResourceIdFromUrl(pathname);

        return dispatch(AreaActions.loadAreaPage(areaResourceId, isMapView))
            .then(() => dispatch(AdActions.refreshAd(true)))
            .catch((error) => {
                dispatch(
                    ErrorActions.errorHandler({
                        errorLocation: 'component.splitMapTemplate.fetchArea',
                        errorClass: 'areaActions',
                        error
                    })
                );
            });
    };

    fetchListings = () => {
        const { dispatch, isAreaUrl, isMobile, currentListing } = this.props;
        const isMapView = this.getShowMap();

        this.fetchListingsCount++;

        // on SRP scroll to top when we fetch listings
        // e.g. filter change
        if (!isMobile && isAreaUrl) {
            const sidebar = document.getElementById('SplitMapTemplate-right-sidebar');

            if (sidebar && sidebar.scrollTo) {
                sidebar.scrollTo(0, 0);
            }
        }

        return dispatch(ListingEngineActions.fetchListings(isMapView))
            .then(() => {
                const { byCoordsListings, byCoordsListingCount } = this.props;

                if (currentListing) {
                    if (byCoordsListingCount) {
                        // find position of current listing within list
                        const indexInList = getCurrentListingIndex(byCoordsListings, currentListing);

                        dispatch(ListingEngineActions.setCurrentListingIndex(indexInList));
                    }
                }
            })
            .catch((err) => {
                // If the user hit a client side url that went past the area's total page count, REDIRECT!
                logger.info({
                    errorLocation: 'SplitMapTemplate.fetchListings',
                    message: `User has gone past area's total page count. Redirecting...`
                });
                return window.router.redirectTo(err.to);
            });
    };

    setMapView = (boolean) => {
        if (!boolean) {
            this.setState(
                {
                    hideMobileRightSidebar: boolean
                },
                () => {
                    window.scrollTo(0, this.LAST_SCROLL_TOP);
                }
            );
        } else {
            this.setState({ hideMobileRightSidebar: boolean });
            this.LAST_SCROLL_TOP = document.scrollingElement.scrollTop;
        }
    };

    handleClosingModalOverlay = yieldCallback((modalName) => {
        const { dispatch } = this.props;

        dispatch(AppActions.toggleOverlay(modalName, false));
    });

    render() {
        const { hideMobileRightSidebar } = this.state;
        const {
            currentListing,
            isAreaUrl,
            isNearMeUrl,
            isMobile,
            isPadOrBuildingUrl,
            isValidAreaPage,
            isValidSearchSlug,
            pathname,
            previewListing,
            showCreateSearchAlert,
            showOptInSearchAlerts
        } = this.props;

        const shouldShowMap = this.getShowMap();

        const showPreview = shouldShowPreview({
            currentListing,
            pathname,
            hideMobileRightSidebar,
            previewListing
        });

        const shouldShowNotFoundPage = !isValidAreaPage || !isValidSearchSlug;

        if (shouldShowNotFoundPage) {
            return <NotFoundPage />;
        }

        const addedProps = {
            key: pathname,
            isMapView: shouldShowMap,
            setMapView: this.setMapView,
            sidebarId: 'SplitMapTemplate-right-sidebar',
            fetchListings: this.fetchListings
        };

        const isAreaPage = isAreaUrl || isNearMeUrl || (isPadOrBuildingUrl && !isMobile);

        return (
            <>
                <div className="SplitMapTemplate">
                    {(shouldShowMap || hideMobileRightSidebar) && (
                        <MapWrapper isMapView={shouldShowMap} useStaticMap={isAreaPage} />
                    )}
                    {showPreview && <SlidingPreviewContainer listing={previewListing} />}
                    <SplitMapSidebar {...this.props} {...addedProps} />
                    {showCreateSearchAlert && (
                        <CreateSearchAlertModal
                            onHidePopup={() => this.handleClosingModalOverlay('createSearchAlert')}
                            triggerLocation="search_results"
                            triggerObject="search_results|alerts_modal"
                        />
                    )}
                    {showOptInSearchAlerts && (
                        <OptInSearchAlertsModal
                            onHidePopup={() => this.handleClosingModalOverlay('optInSearchAlerts')}
                        />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        area: state.area.area,
        border: queryUtils.parse(ownProps.location.search).border,
        byCoordsListingCount: state.listings.listingGroups.byCoords.length,
        byCoordsListings: state.listings.listingGroups.byCoords,
        currentListing: state.currentListingDetails.currentListing,
        existsOutsideOfList: state.currentListingDetails.existsOutsideOfList,
        gmapLoaded: state.app.gmapLoaded,
        isAreaUrl: routeUtils.isAreaUrl(ownProps.location.pathname),
        isNearMeUrl: routeUtils.isNearMeUrl(ownProps.location.pathname),
        isInitialSsrPage: state.app.isInitialSsrPage,
        isListingLoading: state.fetchListing.isListingLoading,
        isLoggedIn: state.user.loggedIn,
        isMapPanning: state.app.isMapPanning,
        isMobile: state.app.device.screenWidth === 'sm',
        isPadOrBuildingUrl: routeUtils.isPadOrBuildingUrl(ownProps.location.pathname),
        isValidAreaPage: state.app.isValidAreaPage,
        isValidSearchSlug: state.app.isValidSearchSlug,
        page: queryUtils.parse(ownProps.location.search).page,
        pathname: ownProps.location.pathname,
        location: ownProps.location,
        isBuildingUrl: routeUtils.isBuildingUrl(ownProps.location.pathname),
        previewListing: state.listings.listingGroups.previewListing,
        query: queryUtils.parse(ownProps.location.search),
        showCreateSearchAlert: state.app.overlays.createSearchAlert,
        showOptInSearchAlerts: state.app.overlays.optInSearchAlerts,
        slug: state.filter.search.slug
    };
};

export default withRouter(connect(mapStateToProps)(SplitMapTemplate));
