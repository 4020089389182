// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';

import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import AppActions from 'app/shared/flux/actions/AppActions';
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import constants from 'app/shared/constants/ConstantsBundle';
import Container from 'app/shared/core/Container';
import Divider from 'app/shared/modules/Divider';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import Row from 'app/shared/core/Row';
import './style.scss';
import Title from 'app/shared/modules/Title';
import userUtils from 'app/shared/utils/userUtils';

const clientToApiDevices = [
    constants.NOTIFICATION_DEVICE_EMAIL,
    constants.NOTIFICATION_DEVICE_MOBILE
];

class OptInSearchAlertsModal extends React.Component {
    static propTypes = {
        onHidePopup: PropTypes.func
    };
    static defaultProps = {
        onHidePopup: () => { }
    };

    constructor(props) {
        super(props);
    }

    handleTurnOnSavedSearchAlerts = () => {
        const { dispatch, onHidePopup, subscriptions, userInfo } = this.props;
        const hasActiveSubscriptions = userUtils.hasActiveSubscriptions(subscriptions);
        let notificationOptions = [];
        let promises = [];

        if (!hasActiveSubscriptions) {
            let updateOptions = {
                stopListings: false,
                stopOther: false
            };
            promises.push(dispatch(NotificationActions.email.updateUserSubscriptions(updateOptions, userInfo.email)));
        }

        forEach(clientToApiDevices, (deviceConstant) => {
            notificationOptions.push({
                deviceGroup: deviceConstant,
                optedOut: false,
                typeGroup: constants.NOTIFICATION_SAVED_SEARCH
            });
        });

        promises.push(dispatch(NotificationActions.notifications.updateSettings(notificationOptions)));
        Promise.all(promises).then(() => {
            dispatch(analyticsEvent(gaEvents.OPT_IN_SAVED_SEARCH));
            dispatch(
                AppActions.toggleNotificationMessage({
                    content: 'Success! Search alert created.'
                })
            );
            onHidePopup();
        });
    };

    render() {
        const { onHidePopup } = this.props;

        return (
            <PopupModal
                paddingBottom="0"
                paddingInline="2x"
                paddingTop="4x"
                position="top"
                onHidePopup={onHidePopup}
            >
                <div className="OptInSearchAlertsModal">
                    <Title size="lg">Alert saved.</Title>
                    <p>
                        To receive alerts, please <br />
                        turn on notifications in your Settings.
                        <br />
                        <br />
                    </p>
                    <Row>
                        <Container>
                            <Button
                                btnType="secondary-outline"
                                full
                                size="lg"
                                onClick={this.handleTurnOnSavedSearchAlerts}
                            >
                                Turn on
                            </Button>
                        </Container>
                    </Row>
                    <Divider />
                    <Center>
                        <p onClick={onHidePopup} className="OptInSearchAlertsModal-link">
                            No thanks
                        </p>
                        <br />
                    </Center>
                </div>
            </PopupModal>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    subscriptions: state.user.subscriptions
});

export default connect(mapStateToProps)(OptInSearchAlertsModal);
