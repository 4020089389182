// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';
import routeUtils from 'app/shared/utils/routeUtils';

export function listingInCurrentArea({ resourceId, currentListing }) {
    if (!currentListing) {
        return;
    }

    let foundArea = false;
    let listingAreas = currentListing.areas.breadcrumbs;

    forEach(listingAreas, (area) => {
        if (area.resourceId === resourceId) {
            foundArea = true;
        }
    });

    return foundArea;
}

export function shouldShowPreview({ currentListing, pathname, hideMobileRightSidebar, previewListing }) {
    let isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(pathname);

    if (!isPadOrBuildingUrl && previewListing && !currentListing && hideMobileRightSidebar) {
        return true;
    }

    return null;
}
