// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';

// Component
import AnimationBase from 'app/shared/modules/Animation';

export const ListingPreviewContainer = styled.article`
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;
    background-color: ${colors['$hpx-white']};
    border: none;
`;

export const ListingPreviewPhoto = styled.div`
    position: relative;
    height: 100%;
    background-color: ${colors['$hpx-grey-600']};
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

export const ListingPreviewPhotoOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: ${zindex['$z-index-step']};
`;

export const ListingPreviewBuildingIcon = styled.div`
    color: ${colors['$hpx-blue-600']};
    display: inline-block;
    padding: 8px 8px 6px 8px;
    background-color: ${colors['$hpx-grey-100']};
    border-radius: 3px;
    margin-bottom: 8px;
`;

export const HeartIcon = styled.div`
    color: ${colors['$hpx-white']};
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    z-index: ${zindex['$z-index-step-over']};
`;

export const Animation = styled(AnimationBase)`
    height: 50px;
    width: 50px;
`;

export const ListingPreview = styled.div`
    width: 100%;
    position: absolute;
    height: 200px;
    left: 0;
    right: 0;
    border: 0;
    top: 0;
    color: ${colors['$hpx-white']};
`;

export const ViewMore = styled.div`
    ${font.tiny};
    z-index: ${zindex['$z-index-step']};
    text-align: center;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 4px;
    text-transform: uppercase;
    font-weight: bold;
`;

export const ListingInfo = styled.div`
    z-index: ${zindex['$z-index-parent']};
    padding: 5px 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const ListingInfoContentLeft = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: 60%;
`;

export const Name = styled.h1`
    ${font.md};
    vertical-align: bottom;
    color: ${colors['$hpx-white']};
    margin: 0;
    padding: 0;
    font-weight: bold;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const City = styled.p`
    ${font.sm};
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ListingInfoContentRight = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: 40%;
    text-align: right;
`;

export const MinPrice = styled.div`
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Beds = styled.div`
    ${font.sm};
`;

export const NYDOSText = styled.p`
    ${font.tiny};
    color: ${colors['$hpx-white']};
    letter-spacing: 0.5px;
`;

export const NYDOSPhoneNumber = styled(NYDOSText)`
    white-space: nowrap;
`;
