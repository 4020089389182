// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import animationData from 'images/animations/heart-whitetowhiteAnimation.json';
import IconBuilding from 'images/icons/building.svg';
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import { listingUtils_checkFavorite } from 'app/shared/utils/listingUtils';
import noPhoto from 'images/noPhoto.png';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import * as S from './styles';

const { object, func } = PropTypes;

class ListingPreview extends Component {
    static propTypes = {
        listing: object.isRequired,
        dispatch: func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            photo: this.getPhoto()
        };
    }

    componentDidMount() {
        const { dispatch, listing } = this.props;

        let photoUrl = this.getPhoto().url;
        if (photoUrl === noPhoto.url) {
            dispatch(
                analyticsEvent(gaEvents.LISTING_NO_IMAGES, {
                    label: listing.aliasEncoded,
                    action: listing.uriV2
                })
            );
        }
    }

    getPhoto = () => {
        const { screenWidth, listing, previewListing } = this.props;
        let photos = [];

        if (screenWidth === 'xl' && listing.largePhotos && listing.largePhotos.length) {
            photos = listing.largePhotos;
        } else if (listing.photos && listing.photos.length) {
            photos = listing.photos;
        } else if (previewListing && previewListing.previewPhoto.url) {
            // todo: hacky fix until photogallery refactor to pass in source urls rather than a listing / previewListing
            let previewPhoto = previewListing.previewPhoto;

            if (screenWidth === 'xl' && includes(previewPhoto.url, 'medium')) {
                previewPhoto.url = previewPhoto.url.replace(/medium/, 'large');
            }

            photos = [previewPhoto];
        } else {
            photos = [listing.previewPhotoMed];
        }

        if (photos[0] && photos[0].url) {
            return photos[0];
        } else {
            return { url: noPhoto, caption: '' };
        }
    };

    yieldToggleUserItem = yieldCallback(() => {
        const { listing, dispatch } = this.props;
        dispatch(UserItemActions.toggleUserItem('favorite', listing));
    });

    handleFavorite = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.yieldToggleUserItem();
    };

    render() {
        const { listing } = this.props;
        const { photo } = this.state;
        const { broker } = listing;
        const isFavorite = listingUtils_checkFavorite(this.props.listing);
        const shouldDisplayBrokerInfo = Boolean(broker.isBrokerExclusiveListing && broker.companyName);
        const hideAddress = listing.address && listing.address.hideStreet;
        let altText = hideAddress ? 'Listing photo' : listing.displayName + ' Photo';
        let bedDisplay = listing.listingMinMaxPriceBeds.bedDisplay;
        if (listing.listingType === 'room') {
            bedDisplay = 'Room for rent';
        }
        if (listing.building) {
            bedDisplay = 'Multiple units';
        }
        const id = `ListingPreview${listing.aliasEncoded}`;

        return (
            <S.ListingPreviewContainer>
                <S.ListingPreviewPhoto>
                    {!listing.building && (
                        <S.HeartIcon id={id} onClick={this.handleFavorite}>
                            <S.Animation
                                isInteractive
                                shouldUseLastFrame={isFavorite}
                                animationId={id}
                                animationData={animationData}
                            />
                        </S.HeartIcon>
                    )}
                    <S.ListingPreviewPhotoOverlay />
                    <ImageLoader altText={altText} image={photo.url} />
                </S.ListingPreviewPhoto>
                <S.ListingPreview>
                    <S.ViewMore>View Details</S.ViewMore>
                    <S.ListingInfo>
                        <S.ListingInfoContentLeft>
                            <S.Name>{hideAddress ? '(Undisclosed Address)' : listing.displayName}</S.Name>
                            <S.City>
                                <span>{listing.address.city}</span>,<span>{' ' + listing.address.state}</span>
                                <span>{' ' + listing.address.zip}</span>
                            </S.City>
                            {shouldDisplayBrokerInfo && (
                                <S.NYDOSText>
                                    {broker.companyName}
                                    {broker.companyPhoneNumber && (
                                        <>
                                            {', '}
                                            <S.NYDOSPhoneNumber as="span">
                                                {broker.companyPhoneNumber}
                                            </S.NYDOSPhoneNumber>
                                        </>
                                    )}
                                </S.NYDOSText>
                            )}
                        </S.ListingInfoContentLeft>
                        <S.ListingInfoContentRight>
                            {listing.building && (
                                <S.ListingPreviewBuildingIcon>
                                    <img src={IconBuilding} width="20px" height="20px" />
                                </S.ListingPreviewBuildingIcon>
                            )}
                            <S.MinPrice>
                                <span>{listing.listingMinMaxPriceBeds.priceDisplay}</span>
                            </S.MinPrice>
                            <S.Beds>{bedDisplay}</S.Beds>
                        </S.ListingInfoContentRight>
                    </S.ListingInfo>
                </S.ListingPreview>
            </S.ListingPreviewContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        screenWidth: state.app.device.screenWidth
    };
};

export default connect(mapStateToProps)(ListingPreview);
