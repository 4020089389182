// @ts-nocheck
/* eslint-enable */
import styled, { keyframes } from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { listingPreview } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

const _slideIn = keyframes`
    100% {
        transform: translateY(0%);
    }
`;

export const SlidingPreviewContainer = styled.div`
    background-color: ${colors['$hpx-grey-600']};

    height: ${listingPreview['listing-preview-height']};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    @media ${viewports['md-and-up']} {
        display: none;
    }
    animation: ${_slideIn} 0.3s forwards;
    -webkit-animation: ${_slideIn} 0.3s forwards;
`;

export const SlidingPreviewContaineSlideIn = styled.div`
    animation: ${_slideIn} 0.3s forwards;
    -webkit-animation: ${_slideIn} 0.3s forwards;
`;
