// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { FetchListingWrapper, FetchAreaWrapper } from 'app/shared/LoadablePages';
import constants from 'app/shared/constants/ConstantsBundle';
import { StyledSplitMapSideBar } from './styles';

export const FetchListingWrapperUrls = [
    '/:addressWithMalone/building',
    '/:addressWithMalone/pad',
    '/:addressWithMalone/pad-for-sublet',
    '/:addressWithMalone/pad-for-sale',
    '/:addressWithMalone/pad-for-auction',
    '/:addressWithMalone/pad-corporate-housing',
    '/:addressWithMalone/pad-foreclosure',
    '/:addressWithMalone/:urlMaloneUnit/pad',
    '/:addressWithMalone/:urlMaloneUnit/pad-for-sublet',
    '/:addressWithMalone/:urlMaloneUnit/pad-for-sale',
    '/:addressWithMalone/:urlMaloneUnit/pad-for-auction',
    '/:addressWithMalone/:urlMaloneUnit/pad-corporate-housing',
    '/:addressWithMalone/:urlMaloneUnit/pad-foreclosure'
];

const nearMeUrlArray = Object.keys(constants.NEAR_ME_ROUTES);
// Keep nearMeUrlArray immutable; make a copy instead
const FetchAreaWrapperUrls = nearMeUrlArray.slice();
FetchAreaWrapperUrls.unshift(`/:areaResourceId/:searchSlug`);

const SplitMapSidebar = (props) => {
    return (
        <>
            <StyledSplitMapSideBar id="SplitMapTemplate-right-sidebar">
                <Switch>
                    <Route path={FetchListingWrapperUrls}>
                        <FetchListingWrapper {...props} />
                    </Route>
                    <Route path={FetchAreaWrapperUrls}>
                        <FetchAreaWrapper {...props} />
                    </Route>
                </Switch>
            </StyledSplitMapSideBar>
        </>
    );
};

export default SplitMapSidebar;
