// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

export const StyledSplitMapSideBar = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    background: ${colors['hpx-white']};
    overflow: scroll;

    @media ${viewports['md-and-up']} {
        margin: auto;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        width: ${variables['sidebar-width-md-bigger-listing-cards']};
    }

    @media ${viewports['xl-and-up-bigger-listing-cards']} {
        width: ${variables['sidebar-width-xl-bigger-listing-cards']};
    }
`;
