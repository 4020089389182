import React from 'react';
import loadable from '@loadable/component';

const MapWrapper = loadable(
    () => import(/* webpackChunkName: "MapWrapper.loadable" */ 'app/shared/wrappers/MapWrapper/component')
);

class LoadableMapWrapper extends React.Component {
    render() {
        return <MapWrapper {...this.props} />;
    }
}

export default LoadableMapWrapper;
